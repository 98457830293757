export const USU_LOGADO = 'USU_LOGADO';
export const USU_LOGAR_SUCCESS = 'USU_LOGAR_SUCCESS';
export const USU_LOGAR = 'USU_LOGAR';
export const USU_LOGOUT = 'USU_LOGOUT';
export const USU_MODULO = 'USU_MODULO';
export const USU_SUBMODULO = 'USU_SUBMODULO';
export const USU_ESCOLA = 'USU_ESCOLA';
export const USU_INFO_ESCOLA = 'USU_INFO_ESCOLA';
export const USU_EXERCICIO = 'USU_EXERCICIO';
export const USU_ULTEXERCICIO = 'USU_ULTEXERCICIO';
export const USU_BUSCA = 'USU_BUSCA';
export const USU_IP = 'USU_IP';
export const MENU = 'MENU';
export const MENU_SIDEBAR = 'MENU_SIDEBAR';
export const ESCOLAS = 'ESCOLAS';
export const ANOSLETIVOS = 'ANOSLETIVOS';
export const ENTIDADE = 'ENTIDADE';
export const PAGINAINICIAL = 'PAGINAINICIAL';
export const LAYOUT = 'LAYOUT';

