import store from '../../store';
import swal from 'sweetalert2';

export const eliminarObjetosDuplicados = (arr, prop) => {
        var novoArray = [];
        var lookup  = {};    
        for (var i in arr) {
            lookup[arr[i][prop]] = arr[i];
        }  
        for (i in lookup) {
            novoArray.push(lookup[i]);
        }
        return novoArray;
    };

export const verificaPermissao = (url) => {

        const infoMenu = store.state.Login.infoMenu;
        const infoLogado = store.state.Login.infoLogado;
            
        const split = url.split('/');    
        var res = infoMenu.filter((menu,idx) => menu.menu_pagina === '/'+split[1]);

        switch (typeof split[3] === 'undefined' ? split[2] : split[3] ){
            case 'novo':
                if ((typeof res[0] !== 'undefined') && (typeof res[0].priv_insere !== 'undefined') && res[0].priv_insere === 0){
                    new swal({
                    icon: 'error',
                    title: 'Sem Permissão',
                    text: 'Usuário sem permissão de acesso.',
                    footer: `<small class="text-danger"><strong>O Usuário ${infoLogado.usu_nome} não possui permissão de INSERÇÃO para essa tabela, altere as permissões do Usuário, efetue um novo login e tente novamente.</strong></small>`
                })
                return false;
                } else return true;
            break;
            case 'editar':
                if ((typeof res[0] !== 'undefined') && (typeof res[0].priv_atualiza !== 'undefined') && res[0].priv_atualiza === 0){
                new swal({
                    icon: 'error',
                    title: 'Sem Permissão',
                    text: 'Usuário sem permissão de acesso.',
                    footer: `<small class="text-danger"><strong>O Usuário ${infoLogado.usu_nome} não possui permissão de ATUALIZAÇÃO para essa tabela, altere as permissões do Usuário, efetue um novo login e tente novamente.</strong></small>`
                })
                return false;
                } else return true;
                break;
            case 'deletar':
                if ((typeof res[0] !== 'undefined') && (typeof res[0].priv_deleta !== 'undefined') && res[0].priv_deleta === 0){
                new swal({
                    icon: 'error',
                    title: 'Sem Permissão',
                    text: 'Usuário sem permissão de acesso.',
                    footer: `<small class="text-danger"><strong>O Usuário ${infoLogado.usu_nome} não possui permissão de EXCLUSÃO para essa tabela, altere as permissões do Usuário, efetue um novo login e tente novamente.</strong></small>`
                })
                return false;
                } else return true;
                break;
            case 'visualizar':
                if ((typeof res[0] !== 'undefined') && (typeof res[0].priv_seleciona !== 'undefined') && res[0].priv_seleciona === 0){
                new swal({
                    icon: 'error',
                    title: 'Sem Permissão',
                    text: 'Usuário sem permissão de acesso.',
                    footer: `<small class="text-danger"><strong>O Usuário ${infoLogado.usu_nome} não possui permissão de VISUALIZAÇÃO para essa tabela, altere as permissões do Usuário, efetue um novo login e tente novamente.</strong></small>`
                })
                return false;
                } else return true;
                break;
            default:
                return true;
            }
    }
