import * as MutationTypes from './MutationTypes';
import Cookies from "js-cookie";
import axios from "@/http";
import axiosip from "axios";

const state = {
    
    infoLogado: [],
    token: Cookies.get('id_token'),
    usuLogado: false,
    Entidade: 0,
    Escola: 0,
    IP: '',
    baseURL: 'https://api.paideia.com.br/',
    infoMenu: [],
    matriculas: [],
    infoEntidade: {},
    infoEscola: {},
};

const mutations = {       
    [MutationTypes.PORTAL_LOGADO](state, response) {
        state.infoLogado = response.user.user
        state.Entidade = Number(response.user.entidade.ent_codigo)        
        state.infoEntidade = response.user.entidade
        state.infoEscola = response.user.escola     

        localStorage.setItem('mct_portal_token', response.user.token)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('mct_portal_token')
        axios.defaults.params = {'mct_entidade': state.Entidade,'mct_ano_letivo': state.Exercicio,'mct_escola': state.Escola,'mct_ip': state.IP} 
    },
    [MutationTypes.PORTAL_LOGOUT](state) {
        state.token = '';
        state.infoLogado = [];
        state.Entidade = 0;
        state.Escola = 0;
        state.infoEntidade = {}
        state.infoEscola = {}     
        Cookies.remove('id_token');
        axios.defaults.params = '';
    },    
    [MutationTypes.PORTAL_LOGAR_SUCCESS](state, response) {
        state.usuLogado = response
    },
    [MutationTypes.PORTAL_IP](state, response) {
        state.IP = response.ip
    },
    [MutationTypes.PORTAL_MATRICULAS](state, response) {
        state.matriculas = response
    },
};

const actions = {
    [MutationTypes.PORTAL_BUSCA]({commit}) {
        axios.get('/user')
            .then((response) => {
                if (response.status === 200){
                    commit(MutationTypes.PORTAL_LOGAR_SUCCESS, true);
                }else {
                    commit(MutationTypes.PORTAL_LOGOUT)
                }
            })
            .catch(function(response,error) {
                if (response.response.status === 401){
                    commit(MutationTypes.PORTAL_LOGOUT)
                }                
              })              
            ;
    },     
    [MutationTypes.PORTAL_LOGADO]({commit}, response) {
        commit(MutationTypes.PORTAL_LOGADO, response);
        response.router.push({path: '/portal/matriculas'})       

    },
    [MutationTypes.PORTAL_LOGAR_SUCCESS]({commit}, response) {
        commit(MutationTypes.PORTAL_LOGAR_SUCCESS, response);
    },
    [MutationTypes.PORTAL_LOGOUT]({commit}) {
        commit(MutationTypes.PORTAL_LOGOUT);
    },    
    [MutationTypes.PORTAL_IP]({commit}) {
        axiosip.defaults.baseURL = 'http://localhost:8080/';
        axiosip.get('https://api.ipify.org?format=json')
        .then(function(response) {
            commit(MutationTypes.PORTAL_IP,response.data);
        })
        .catch(function(response) {
            console.log(response)
        })
    },
    async [MutationTypes.PORTAL_MATRICULAS]({commit}) {
        await axios.get('/matriculas')
        .then(function(response) {
            commit(MutationTypes.PORTAL_MATRICULAS,response.data.data);
        })
        .catch(function(response) {
            console.log(response)
        })
    },
};

const getters = {
    infoLogado: state => state.infoLogado,
    usuLogado: state => state.usuLogado,
    Entidade: state => state.Entidade,
    Escola: state => state.Escola,
    infoEntidade: state => state.infoEntidade,
    infoEscola: state => state.infoEscola,
    IP: state => state.IP,
    matriculas: state => state.matriculas,
};

export default{
    namespaced: true,
    state, 
    actions, 
    mutations, 
    getters
}