<template>
<div class="cssmenu">
    <ul class="nav navbar-nav d-md-down-none css-menu">
        <li class="nav-item px-3 dropdown has-sub" v-for="(menu,index) in PaisMenu()" :key="index">
            <a class="nav-link"  href="#" @click="getIframe($event,menu)">{{ menu.menu_descricao }}</a>
            <ul class="has-first">
                <li :class="{ 'has-sub': filhosMenu(filho.menu_codigo).length > 0 }"  v-for="(filho,index) in filhosMenu(menu.menu_codigo)" :key="index">
                    <a class="Hyperlink" href="#" @click="getIframe($event,filho)"><span>{{ filho.menu_descricao }}</span></a>
                    <ul>
                        <li v-for="(filho2,index) in filhosMenu(filho.menu_codigo)" :key="index">
                            <a class="Hyperlink" href="#" @click="getIframe($event,filho2)">{{ filho2.menu_descricao }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>            
    </ul>
</div>
</template>

<script>
import Vue from 'vue'
import { mapState } from "vuex";

export default {
    data () {
        return {
            listaMenu: []
        }
    },
    methods : {       
        PaisMenu: function () {   
            if (this.listaMenu != []){ 
                return this.listaMenu.filter(pais => {
                    return pais.menu_pai === null
                })
            }
        },
        filhosMenu: function (pai) {  
            if (this.listaMenu != []){ 
                return this.listaMenu.filter(filho => {
                    return filho.menu_pai === pai
                })
            }
        },
        getIframe: function (ev, pagina) {
            ev.preventDefault()
            if (pagina.menu_pagina != ''){
                this.$router.push({path: pagina.menu_pagina,  props: pagina})  
            }
        },
        logout() {
            this.$store.dispatch('Login/USU_LOGOUT');            
        } ,
        sidebarToggle (e) {
            e.preventDefault()
            document.body.classList.toggle('sidebar-hidden')
            },              
    },
    computed: {
        ...mapState('Login',['infoLogado','infoModulo','infoMenu'])
    }, 
    watch: {
        infoMenu () {
            this.listaMenu = this.infoMenu
        }
    },
    created() {
        this.listaMenu = this.infoMenu
    },
    updated() {
        this.listaMenu = this.infoMenu
    }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/menu";
</style>
