<template>
  <div id="app" class="d-flex flex-column">

    <nav id="app-navbar" class="navbar navbar-expand-lg d-flex flex-row justify-content-between">
        <img class="app-logo" src="@/assets/images/paideia_icon.png" alt="Logo">
        <img src="@/assets/images/paideia_text.png" alt="Logo" class="app-logo-full" :class="!sidebarReduce && sidebarActive ? '' : 'sidebar-reduced'">     
        <a class="navbar-brand" href="#" @click="sidebarActive = !sidebarActive"><i class="fa fa-bars"></i></a>
        <div class="d-flex flex-row justify-content-start w-100">
          <mct-menu @setPatch="setPatch"></mct-menu>
        </div>      
        <!-- <a class="navbar-brand" href="#" @click="asideActive = !asideActive"><i class="fa fa-bars"></i></a>           -->
    </nav>
    
    <div id="app-wrapper" class="wrapper">
      <side-bar :sidebarActive.sync='sidebarActive' :sidebarReduce.sync='sidebarReduce'/>
      
      <div id="app-content" class="content" :class="{'sidebar-notactive' : !sidebarActive,'sidebar-active' : sidebarActive,'aside-active' : asideActive, 'sidebar-reduced' : sidebarReduce && sidebarActive}">        
        <div class="px-2 py-2 d-flex flex-row" style="background-color: #FFF; border-bottom: 1px solid var(--border-color); gap: 8px; max-height: 40px;">
          <div class="d-flex flex-row align-items-center p-0 m-0" style="" >
            <label class="m-0 text-dark" style="font-size: 10px;" for="index-anos-letivos">Ano Letivo: </label>
            <select class="form-control" style="font-size: 0.8rem; font-weight: 600; color: var(--light); background-color: var(--color-secondary); border: 0; outline: 0; padding: 6px 4px; height: auto; border-radius: 2px; cursor: pointer;" id="index-anos-letivos" v-model="anoLetivoAtual" @change="changeAnoLetivo">
              <option style="font-size: 0.8rem; font-weight: 600; color: var(--light);" v-for="(anoLetivo, index) in AnosLetivos" :key="index" :value="anoLetivo.ano_codigo" >{{anoLetivo.ano_codigo}}</option>
            </select>
          </div>          
          <div class="d-flex flex-row align-items-center p-0 m-0">
            <label class="m-0 text-dark" style="font-size: 10px;" for="index-escolas">Escola: </label>
            <select class="form-control" style="font-size: 0.8rem; font-weight: 600; color: var(--light); background-color: var(--color-secondary); border: 0; outline: 0; padding: 6px 4px; height: auto; border-radius: 2px; cursor: pointer;" id="index-escolas" v-model="escolaAtual" @change="changeEscola">
              <option style="font-size: 0.8rem; font-weight: 600; color: var(--light);" v-for="(escola, index) in Escolas" :key="index" :value="escola.esc_codigo" >{{escola.esc_nome}} <span class="text-muted">[{{ escola.esc_abreviatura }}]</span></option>
            </select>
          </div>          
        </div>
        <router-view></router-view>
      </div>

      <!-- <a-side :asideActive="asideActive"/> -->
    </div>

    <div id="app-footer" class="footer" :class="{'sidebar-notactive' : !sidebarActive,'sidebar-active' : sidebarActive,'aside-active' : asideActive, 'sidebar-reduced' : sidebarReduce && sidebarActive}">
      <div>
        <a href="https://www.mapes.com.br">Mapes Softwares</a>
        <span class="ml-1">&copy; {{moment().year()}}</span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Desenvolvido por</span>
        <a href="https://www.mapes.com.br">Mapes Softwares</a>
      </div>      
    </div>
    <modal/>
  </div>    
</template>

<script>
import { mapState } from 'vuex'

import MctTopo from '@/components/comum/MctTopo.vue'
import MctMenu from '@/components/comum/MctMenu.vue'

import ASide from './LayoutDefaultASide.vue';
import SideBar from './LayoutDefaultSideBar.vue';

import { Modal } from '@/components/modal'

export default {
  name: 'LayoutDefault',
  data () {
    return {
      sidebarActive: true,
      asideActive: false,
      sidebarReduce: true,
      anoLetivoAtual: null,
      escolaAtual: null
    }
  },
  components: {
    MctTopo,
    MctMenu,
    ASide,
    SideBar,
    Modal
  },
  methods: {
      changeAnoLetivo (event) {
        const anoLetivoSelecionado = event.target.value;
        if (anoLetivoSelecionado !== this.Exercicio) {
          this.$swal({
            title: 'Deseja realmente trocar de Ano Letivo?',
            text: "Ao trocar de Ano Letivo você será redirecionado para a página inicial, e perderá as alterações, caso esteja alterando ou inserindo algo, cancele a troca de Ano Letivo, salve as alterações e selecione novamente o Ano Letivo.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
            }).then((result) => {
              if (result.value) {
                this.$store.dispatch('Login/USU_EXERCICIO', anoLetivoSelecionado);
                this.$router.push({ path: '/dashboards' })
              } else {
                console.log(this.anoLetivoAtual, this.Exercicio)
              }                     
          })
        }
        // this.$router.go(0)
        // this.$router.go(this.$router.currentRoute)
      },
      changeEscola (event) {
        const escolaSelecionado = event.target.value;
        if (escolaSelecionado !== this.Escola) {
          this.$swal({
            title: 'Deseja realmente trocar de Escola?',
            text: "Ao trocar de Escola você será redirecionado para a página inicial, e perderá as alterações, caso esteja alterando ou inserindo algo, cancele a troca de Escola, salve as alterações e selecione novamente o Escola.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
            }).then((result) => {
              if (result.value) {
                this.$store.dispatch('Login/USU_ESCOLA', escolaSelecionado);
                this.$router.push({ path: '/dashboards' });
              } else {
                this.escolaAtual = this.Escola;
              }                     
          })
        }
      },
      setPatch (pagina) {
        if (this.infoLogado.usu_codigo){        
          if (pagina.menu_pagina != ''){
            this.$router.push({path: pagina.menu_pagina,  props: pagina})  
          }
        }else{
            this.$router.push({path: '/logar'})  
        }
      },
      setSide (pagina) {
        if (this.infoLogado.usu_codigo){        
          if (pagina != ''){
            this.$router.push({path: pagina})  
          }
        }else{
            this.$router.push({path: '/logar'})  
        }
      },
  },  
  created () {
    this.anoLetivoAtual = this.Exercicio;
    this.escolaAtual = this.Escola;
  },
  mounted () {
    if (!this.infoLogado.usu_codigo){    
      this.showSideBar = false    
      this.$router.push({path: '/logar'})  
    } else{
      this.showSideBar = true    
    }
    // this.anoLetivoAtual = this.Exercicio;
    // this.escolaAtual = this.Escola;
  },
  updated () {
    if (!this.infoLogado.usu_codigo){        
      this.showSideBar = false
      this.$router.push({path: '/logar'})  
    } else{
      this.showSideBar = true
    }    
    // this.anoLetivoAtual = this.Exercicio;
    // this.escolaAtual = this.Escola;
  },
  computed: {
    ...mapState('Login',['infoLogado','infoModulo','infoSubModulo','Entidade','Exercicio','Competencia','AnosLetivos','Escola','Escolas']),
  },
  sockets: {
    mctalerta: function (data) {
      this.$store.dispatch('App/APP_ATU_ALERTAS',data);
      this.$awn.info(`Notificação Recebida: ${data.ale_descricao}.`)
    },
  },  
}
</script>